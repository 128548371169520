.vacancy {
  @include make-spacer(pt, 8);
  @include make-spacer(pt, 12, sm);
  @include make-spacer(pt, 20, lg);

  overflow: hidden;

  &__item {
    @include make-spacer(mt, 8);
    @include make-spacer(mt, 12, sm);

    &:first-child {
      @include make-spacer(mt, 0);
    }
  }

  &__grid {
    @include make-spacer(mx, auto, lg);

    display: grid;
    grid-template-columns: 1fr;
    max-width: var(--container-width);

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__body {
    @include make-spacer(px, 3);
    @include make-spacer(px, 8, sm);
    @include make-spacer(pr, 4, lg);
    @include make-spacer(pl, 20, lg);

    grid-column: 1;

    @include media-breakpoint-up(lg) {
      grid-column: 1 / span 2;
    }
  }

  &__sidebar {
    @include make-spacer(px, 3);
    @include make-spacer(px, 8, sm);
    @include make-spacer(pl, 0, lg);
    @include make-spacer(pr, 20, lg);
    @include make-spacer(py, 16);
    @include make-spacer(py, 20, sm);
    @include make-spacer(py, 0, lg);

    background-color: var(--gray-100);
  }

  &__heading {
    @include make-spacer(mb, 8);
    @include make-spacer(mb, 12, md);
    @include make-spacer(px, 3);
    @include make-spacer(px, 8, sm);
    @include make-spacer(pr, 4, lg);
    @include make-spacer(pl, 20, lg);

    grid-column: 1;

    @include media-breakpoint-up(lg) {
      grid-column: 1 / span 2;
    }
  }

  &__title {
    @include font-size(14px, 20px);
    @include make-spacer(mb, 4);
    @include make-spacer(mb, 6, sm);

    text-transform: uppercase;
    font-family: var(--headings-font-family);
    font-weight: var(--extra-bold);

    @include media-breakpoint-up(sm) {
      @include font-size(18px, 25px);
    }
  }

  &__form {
    @include make-spacer(pt, 12);
    @include make-spacer(pb, 16);
    @include make-spacer(py, 20, sm);
    @include make-spacer(mt, 0);
    @include make-spacer(mt, 20, sm);

    position: relative;
    grid-row: 3;
    grid-column: 1;

    &::before,
    &::after {
      @include size(100%);

      position: absolute;
      top: 0;
      background-color: var(--white);
      content: '';
    }

    &::before {
      left: 100%;
    }

    &::after {
      right: 100%;
    }

    @include media-breakpoint-up(lg) {
      grid-column: 1 / span 3;
    }
  }

  &__form-title {
    @include font-size(24px, 34px);
    @include make-spacer(mb, 8);

    text-transform: uppercase;
    font-family: var(--headings-font-family);
    font-weight: var(--extra-bold);
  }

  @include media-breakpoint-up(sm) {
    background-color: var(--gray-100);
  }
}
