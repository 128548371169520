.games-slider {

  &__header {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  &__pagination {
    @include make-spacer(mb, 1);
    @include make-spacer(mb, 3, sm);
    @include make-spacer(ml, auto);

    align-self: center;
  }

  &__title {
    max-width: 50%;
  }

  &__item {
    @include make-spacer(px, 1, sm);
    @include make-spacer(px, 2, md);
  }

  &__ratio {
    position: relative;
    flex-shrink: 0;
    padding-top: 175%;
    height: 0;

    img {
      @include size(100%);

      display: block;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }

  &__carousel {
    @include make-spacer(mb, 8);
    @include make-spacer(mb, 12, md);

    @include media-breakpoint-up(sm) {
      margin-left: -5px;
      width: calc(100% + 10px);
    }

    @include media-breakpoint-up(md) {
      margin-left: -10px;
      width: calc(100% + 20px);
    }
  }

  &__button {
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: auto;
    }
  }
}
