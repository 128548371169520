.article {
  @include make-spacer(pt, 8);
  @include make-spacer(py, 12, sm);
  @include make-spacer(py, 20, lg);

  &__grid {
    @include make-spacer(px, 20, lg);
    @include make-spacer(mx, auto, lg);

    max-width: var(--container-width);

    @include media-breakpoint-up(lg) {
      display: grid;
      grid-template-columns: 1fr 400px;
      grid-column-gap: 20px;
    }
  }

  &__header {
    @include make-spacer(px, 3);
    @include make-spacer(px, 8, sm);
    @include make-spacer(px, 0, lg);

    @include media-breakpoint-up(lg) {
      grid-column: 1 / 3;
      padding-right: 420px;
    }
  }

  &__body {
    @include make-spacer(px, 3);
    @include make-spacer(px, 8, sm);
    @include make-spacer(px, 0, lg);
  }

  &__title {
    @include make-spacer(mb, 8);
    @include make-spacer(mb, 12, md);
  }

  &__date {
    @include make-spacer(mb, 6);
    @include font-size(14px, 16px);

    display: block;
    color: var(--gray);
  }

  &__ratio {
    @include make-spacer(mb, 8);
    @include make-spacer(mb, 12, md);

    position: relative;
    padding-top: 56.25%;
    height: 0;
    overflow: hidden;
  }

  &__image {
    @include size(100%);

    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  &__text {
    @include font-size(16px, 24px);
    @include make-spacer(mb, 12);
    @include make-spacer(px, 4, sm);
    @include make-spacer(py, 8, sm);

    h1 {
      @include make-spacer(mb, 8);
      @include make-spacer(mb, 12, md);
    }

    h2 {
      @include make-spacer(mb, 6);
      @include make-spacer(mb, 8, md);
    }

    h3 {
      @include make-spacer(mb, 4);
      @include make-spacer(mb, 6, md);
    }

    h4 {
      @include make-spacer(mb, 4);
      @include make-spacer(mb, 6, md);
    }

    p {
      @include font-size(16px, 24px);
      @include make-spacer(mb, 8);

      &:last-child {
        @include make-spacer(mb, 0);
      }

      @include media-breakpoint-up(sm) {
        @include font-size(18px, 32px);
      }
    }

    ul {
      @include reset-list;
      @include make-spacer(mb, 4);
      @include make-spacer(mb, 8, sm);
      @include make-spacer(mb, 12, md);

      li {
        @include make-spacer(pl, 4);
        @include make-spacer(pl, 5, sm);
        @include make-spacer(pl, 6, md);
        @include make-spacer(mb, 3);
        @include make-spacer(mb, 4, md);
        @include font-size(14px, 20px);

        position: relative;

        &::before {
          @include size(10px);

          position: absolute;
          top: 4px;
          left: 0;
          background-color: var(--pink);
          content: '';
        }

        &:last-child {
          @include make-spacer(mb, 0);
        }

        @include media-breakpoint-up(md) {
          @include font-size(16px, 24px);

          &::before {// stylelint-disable-line
            top: 7px;
          }
        }
      }
    }

    ol {
      @include reset-list;
      @include make-spacer(mb, 4);
      @include make-spacer(mb, 8, sm);
      @include make-spacer(mb, 12, md);

      li {
        @include make-spacer(pl, 4);
        @include make-spacer(pl, 5, sm);
        @include make-spacer(pl, 6, md);
        @include make-spacer(mb, 3);
        @include make-spacer(mb, 4, md);
        @include font-size(14px, 20px);

        position: relative;
        counter-increment: li;

        &::before {
          @include make-spacer(mr, 2);

          position: absolute;
          top: 0;
          left: 0;
          color: var(--pink);
          font-weight: var(--bold);
          content: counter(li);
        }

        &:last-child {
          @include make-spacer(mb, 0);
        }

        @include media-breakpoint-up(md) {
          @include font-size(16px, 24px);
        }
      }
    }

    a {

      &:hover {
        text-decoration: underline;
      }
    }

    b {
      display: block;
    }

    mark {
      background-color: transparent;
      color: var(--red);
    }

    @include media-breakpoint-up(sm) {
      @include font-size(18px, 32px);

      background-color: var(--white);
    }
  }

  &__cosials {
    @include make-spacer(pb, 14);
  }

  &__sidebar {
    @include make-spacer(py, 14);
    @include make-spacer(py, 20, sm);
    @include make-spacer(py, 0, md);
    @include make-spacer(px, 3);
    @include make-spacer(px, 8, sm);
    @include make-spacer(px, 0, lg);

    background-color: var(--gray-100);
  }

  @include media-breakpoint-up(sm) {
    background-color: var(--gray-100);
  }
}
