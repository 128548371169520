.news-popular {

  &__header {
    display: grid;
    grid-template-columns: 1fr auto;
  }

  &__link {
    @include font-size(12px);
    @include make-spacer(mb, 6);

    display: block;
    align-self: center;
    color: var(--white);
    font-family: var(--headings-font-family);
    font-weight: var(--extra-bold);

    &:hover {
      color: var(--yellow);
    }

    @include media-breakpoint-up(sm) {
      @include font-size(16px);
    }
  }

  &__grid {
    @include make-spacer(px, 6);
    @include make-spacer(px, 0, md);

    display: grid;
    grid-template-columns: repeat(3, 290px);
    grid-column-gap: 20px;

    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(3, 335px);
    }

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__wrapper {
    @include make-spacer(pb, 12);
    @include make-spacer(pt, 2);
    @include make-spacer(pb, 0, md);

    display: grid;
    position: relative;
    margin: 0 -15px;
    overflow-y: auto;

    @include media-breakpoint-up(sm) {
      margin: 0 -40px;
    }

    @include media-breakpoint-up(md) {
      margin: 0;
      overflow-y: visible;
    }
  }
}
