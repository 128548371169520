:root {
  --white: #fff;
  --black-primary: #010101;
  --black-secondary: #0c0d10;
  --black-800: #17191F;
  --black-text: #232323;
  --black-700: #22272c;
  --gray-200: #e4e2e2;
  --gray-100: #f3f3f5;
  --gray: #979797;
  --pink: #ef3c8e;
  --pink-active: #c92771;
  --red: #ff1f1f;
  --yellow: #ffdc4a;
  --yellow-focus: #ffd113;
  --yellow-alpha-05: #{rgba(#ffdc4a, 0.5)};
  --black-primary-alpha-016: #{rgba(#010101, 0.16)};
  --black-primary-alpha-04: #{rgba(#010101, 0.5)};
  --black-primary-alpha-08: #{rgba(#010101, 0.8)};
}
