.vr-about {

  &__grid {
    display: grid;
    grid-row-gap: 35px;

    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(3, 340px);
      grid-column-gap: 45px;
    }

    @include media-breakpoint-up(lg) {
      grid-column-gap: 110px;
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__header {
    color: var(--yellow);
  }

  &__content {
    @include make-spacer(pb, 12);
    @include make-spacer(pt, 2);

    display: grid;
    position: relative;
    margin: 0 -15px;
    overflow-y: auto;

    @include media-breakpoint-up(sm) {
      margin: 0 -40px;
    }

    @include media-breakpoint-up(lg) {
      margin: 0;
    }
  }

  &__wrapper {
    @include make-spacer(px, 4);
    @include make-spacer(px, 0, lg);
  }
}
