.menu-tab {

  &__carousel {
    @include media-breakpoint-up(md) {
      display: grid;
      grid-column: 1 /span 2;
    }
  }

  &__content {
    text-align: center;

    @include media-breakpoint-up(md) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 60px;
      grid-column-gap: 20px;
      text-align: left;
    }
  }

  &__category {
    @include make-spacer(mt, 12);
    @include make-spacer(mb, 8);
    @include make-spacer(mb, 0, md);
    @include make-spacer(mt, 0, md);

    text-align: left;

    @include media-breakpoint-up(md) {
      grid-row: 1;
      grid-column: 3;
    }
  }

  &__pagination {
    @include make-spacer(mt, 8);
    @include make-spacer(mt, 0, md);
    @include flex(center, center);

    grid-column: 1;
    color: var(--white);

    @include media-breakpoint-up(md) {
      justify-content: flex-start;
    }
  }

  &__ratio {
    position: relative;
    padding-top: 56.25%;
    height: 0;
    overflow: hidden;
  }

  &__image {
    @include size(100%);

    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__list {
    @include make-spacer(pt, 6);
    @include make-spacer(pb, 4);
    @include make-spacer(px, 6);

    border-bottom: 1px solid var(--black-700);
    font-family: var(--headings-font-family);

    &:first-child {
      @include make-spacer(pt, 8);
    }

    &:last-child {
      @include make-spacer(pb, 10);

      border-bottom: 0;
    }
  }

  &__link {
    grid-column: 2;
    text-align: center;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      text-align: right;
    }
  }

  &__text {
    @include font-size(16px, 18px);
  }
}
