.marquee {
  @include make-spacer(py, 4);
  @include make-spacer(py, 3, md);
  @include font-size(12px, 20px);

  background-color: var(--yellow);
  color: var(--black-secondary);
  font-family: var(--headings-font-family);
  font-weight: var(--extra-bold);
  overflow: hidden;

  @include media-breakpoint-up(md) {
    @include font-size(16px, 24px);
  }

  &__item {
    @include make-spacer(mx, 6);
    @include make-spacer(mx, 10, sm);

    text-transform: uppercase;
  }
}
