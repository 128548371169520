.icon-LinkedIn {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-arrow-down {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-arrow {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-clock {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-close {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-delete {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-expand-left {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-expand-right {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-expand {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-facebook {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-instagram {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-loading {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-monitor {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-paperclip {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-twitter {
  width: 1em;
  height: 1em;
  fill: initial;
}
