.vacancies-section {

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;

    &__tabs {
      text-transform: capitalize;
    }

    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 40px 20px;
    }

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__title {
    @include make-spacer(mb, 6);
    @include font-size(14px, 16px);

    min-height: 42px;
    text-transform: uppercase;

    @include media-breakpoint-up(sm) {
      @include font-size(16px, 22px);
    }

    @include media-breakpoint-up(md) {
      @include font-size(18px, 24px);
    }
  }

  &__block {
    @include make-spacer(px, 4);
    @include make-spacer(py, 6);

    display: block;
    border-bottom: 1px solid var(--black-700);
    text-align: left;

    @include media-breakpoint-up(sm) {
      @include make-spacer(py, 8);
    }

    @include media-breakpoint-up(md) {
      @include make-spacer(px, 6);
    }
  }

  &__item {
    position: relative;
  }

  &__text {
    @include font-size(16px, 22px);

    text-align: left;
  }

  &__button {
    display: block;
    position: relative;
    font-family: var(--headings-font-family);
    font-weight: var(--extra-bold);
  }

  &__details {
    @include make-spacer(py, 8);
    @include make-spacer(px, 6);
    @include font-size(12px, 16px);

    text-align: center;

    @include media-breakpoint-up(sm) {
      @include font-size(16px, 22px);
    }
  }
}
