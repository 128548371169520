@import "https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/magnific-popup.css";

.gallery {
  @include make-spacer(mx, auto);

  overflow: hidden;

  &--visible {
    overflow: visible;
  }

  &__item {
    @include make-spacer(mb, 4);
    @include make-spacer(mb, 12, sm);

    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;

    &:last-child {
      @include make-spacer(mb, 0);
    }

    @include media-breakpoint-up(sm) {
      grid-template-columns: 1fr 1fr;
      position: relative;
      width: 125%;

      &:nth-child(odd) {
        @include make-spacer(pl, 8);
      }

      &:nth-child(even) {
        @include make-spacer(pr, 8);

        left: -25%
      }
    }
  }

  &__image {
    @include size(100%);

    display: block;
    object-fit: cover;
  }

  &__wrapper {
    margin-bottom: 20px;
    width: 100%;

    > * {
      @include size(100%);
    }

    @include media-breakpoint-up(sm) {
      width: calc(50% - 10px);
    }

    @include media-breakpoint-up(md) {
      width: calc(33% - 20px);
    }
  }
}
