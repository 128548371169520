.copy-center-gallery {

  &__grid {
    @include make-spacer(pl, 8, sm);
    @include make-spacer(pl, 20, lg);
    @include make-spacer(pl, 0, xl);
    @include make-spacer(pr, 0, sm);

    @include media-breakpoint-up(xl) {
      display: grid;
      grid-template-columns: 1fr 1240px 1fr;
    }
  }

  &__slider {
    @include make-spacer(mb, 8);
    @include make-spacer(mb, 12, md);

    width: 100%;

    @include media-breakpoint-up(xl) {
      grid-column: 2 / 4;
    }
  }

  &__image {
    @include size(100%);

    position: absolute;
    top: 0;
    left: 0;
    display: block;
    object-fit: cover;
  }

  &__item {
    @include make-spacer(px, 3);
    @include make-spacer(pr, 4, sm);
    @include make-spacer(pl, 0, sm);
  }

  &__ratio {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-top: 56.25%;
  }

  &__pagination {
    text-align: center;
  }
}
