.text-block {
  @include font-size(16px, 24px);
  @include make-spacer(mb, 12);
  @include make-spacer(px, 4, sm);
  @include make-spacer(py, 8, sm);

  h1 {
    @include make-spacer(mb, 8);
    @include make-spacer(mb, 12, md);
  }

  h2 {
    @include make-spacer(mb, 6);
    @include make-spacer(mb, 8, md);
  }

  h3 {
    @include make-spacer(mb, 4);
    @include make-spacer(mb, 6, md);
  }

  h4 {
    @include make-spacer(mb, 4);
    @include make-spacer(mb, 6, md);
  }

  p {
    @include font-size(16px, 24px);
    @include make-spacer(mb, 8);

    &:last-child {
      @include make-spacer(mb, 0);
    }

    @include media-breakpoint-up(sm) {
      @include font-size(18px, 32px);
    }
  }

  ul {
    @include reset-list;
    @include make-spacer(mb, 4);
    @include make-spacer(mb, 8, sm);
    @include make-spacer(mb, 12, md);

    li {
      @include make-spacer(pl, 4);
      @include make-spacer(pl, 5, sm);
      @include make-spacer(pl, 6, md);
      @include make-spacer(mb, 3);
      @include make-spacer(mb, 4, md);
      @include font-size(14px, 20px);

      position: relative;

      &::before {
        @include size(10px);

        position: absolute;
        top: 4px;
        left: 0;
        background-color: var(--pink);
        content: '';
      }

      &:last-child {
        @include make-spacer(mb, 0);
      }

      @include media-breakpoint-up(md) {
        @include font-size(16px, 24px);

        &::before {// stylelint-disable-line
          top: 7px;
        }
      }
    }
  }

  ol {
    @include reset-list;
    @include make-spacer(mb, 4);
    @include make-spacer(mb, 8, sm);
    @include make-spacer(mb, 12, md);

    li {
      @include make-spacer(pl, 4);
      @include make-spacer(pl, 5, sm);
      @include make-spacer(pl, 6, md);
      @include make-spacer(mb, 3);
      @include make-spacer(mb, 4, md);
      @include font-size(14px, 20px);

      position: relative;
      counter-increment: li;

      &::before {
        @include make-spacer(mr, 2);

        position: absolute;
        top: 0;
        left: 0;
        color: var(--pink);
        font-weight: var(--bold);
        content: counter(li);
      }

      &:last-child {
        @include make-spacer(mb, 0);
      }

      @include media-breakpoint-up(md) {
        @include font-size(16px, 24px);
      }
    }
  }

  a {

    &:hover {
      text-decoration: underline;
    }
  }

  b {
    display: block;
  }

  mark {
    background-color: transparent;
    color: var(--red);
  }

  @include media-breakpoint-up(sm) {
    @include font-size(18px, 32px);

    background-color: var(--white);
  }
}
