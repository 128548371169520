.pagination {
  @include reset-list;
  @include flex(center, center, $display: inline-flex);
  @include font-size(16px, 24px);

  font-family: var(--headings-font-family);
  font-weight: var(--extra-bold);

  &__link {
    @include transition(color);
    @include flex(center, center);
    @include make-spacer(px, 3);
    @include make-spacer(py, 2);

    color: var(--white);

    &:hover {
      color: var(--pink);
    }

    &.is-disabled {
      pointer-events: none;

      .pagination__icon {
        fill: var(--gray)
      }
    }

    &.is-active {
      color: var(--gray);
      pointer-events: none;
    }
  }

  &__icon {
    @include font-size(26px);
  }
}
