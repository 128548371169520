.tabs-default {
  @include make-spacer(mb, 8);
  @include make-spacer(mt, 9);

  &.is-adaptive {
    @include size(100%, 60px);

    position: relative;
    cursor: pointer;

    &::before {
      @include make-spacer(px, 3);
      @include font-size(12px, 60px);
      @include size(100%);

      display: block;
      background-color: var(--black-secondary);
      color: var(--white);
      font-family: var(--headings-font-family);
      font-weight: var(--bold);
      content: attr(data-title);
    }

    &::after {
      @include transition(transform);
      @include size(10px);

      display: block;
      position: absolute;
      top: 25px;
      right: 20px;
      transform: rotateZ(135deg);
      transform-origin: center;
      border-top: 2px solid var(--white);
      border-right: 2px solid var(--white);
      content: '';
    }

    &.is-active {

      &::after {
        transform: rotateZ(-45deg);
      }
    }

    .tabs-default {

      &__item {
        @include make-spacer(px, 2);

        align-items: flex-start;
        color: var(--black-text);

        &.is-active {
          color: var(--pink);
        }
      }

      &__wrapper {
        @include transition(opacity);

        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        visibility: hidden;
        opacity: 0;
        z-index: map-get($z-indexes, dropdown);
        background-color: var(--white);
        width: 100%;
        text-align: left;
        color: var(--black-text);

        &.is-active {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  &__wrapper {
    @include flex(space-between);

    background-color: var(--black-secondary);
    width: 100%;
    text-align: center;
  }

  &__item {
    @include font-size(12px, 22px);
    @include flex(center, center, $direction: column);
    @include make-spacer(py, 4);
    @include transition(color);

    position: relative;
    border-right: 1px solid var(--black-700);
    width: 100%;
    text-align: center;
    color: var(--gray);
    font-family: var(--headings-font-family);

    &:last-child {
      border-right: 0;
    }

    &.is-active {
      color: var(--white);

      &::after {
        background-color: var(--white);
      }
    }

    &::after { // stylelint-disable-line
      @include transition(background-color);
      @include size(100%, 5px);

      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      content: '';
    }

    &:hover {
      cursor: pointer;
      color: var(--white);

      &::after {
        background-color: var(--white);
      }
    }

    @include media-breakpoint-up(sm) {
      @include font-size(16px, 22px);

      font-weight: var(--bold);
    }

    @include media-breakpoint-up(md) {
      @include flex($direction: row);
      @include font-size(18px, 22px);

      text-transform: uppercase;
      font-weight: var(--extra-bold);
    }
  }

  &__title {
    @include make-spacer(px, 2);

    display: block;
  }

  &__text {
    @include font-size(12px, 22px);
    @include make-spacer(ml, 4, md);

    display: block;
    text-transform: none;
    font-weight: var(--regular);

    @include media-breakpoint-up(sm) {
      @include font-size(14px, 22px);
    }

    @include media-breakpoint-up(lg) {
      @include font-size(16px, 22px);

      display: inline;
    }
  }

  &--light {
    background-color: var(--white);

    .tabs-default {

      &__item {
        border-right: 1px solid var(--gray);
        background-color: var(--white);

        &:last-child {
          border-right: 0;
        }

        &.is-active {
          color: var(--black-primary);

          &::after { // stylelint-disable-line
            background-color: var(--black-primary);
          }
        }

        &:hover {
          color: var(--black-primary);

          &::after { // stylelint-disable-line
            background-color: var(--black-primary);
          }
        }
      }
    }
  }

  &--accent {

    .tabs-default {

      &__item {
        color: var(--white);
        font-weight: var(--extra-bold);

        @include media-breakpoint-up(sm) {
          font-size: 18px;
        }

        &.is-active {
          color: var(--yellow);

          &::after {
            background-color: var(--yellow);
          }
        }
      }
    }
  }

  &--sm {
    margin: 0;
  }
}

