/* Slider */

.slick-slider {
  display: block;
  position: relative;
  -webkit-touch-callout: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  overflow: hidden;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: hand;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;

  &::before,
  &::after {
    display: table;
    content: '';
  }

  &::after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  display: none;
  float: left;
  outline: none;
  height: 100%;
  min-height: 1px;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    border: 1px solid transparent;
    height: auto;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-next {

  .slider-arrow {
    transform: scale(-1);
  }
}

.slick-arrow {
  @include size(rem(32px));
  @include flex(center, center);

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  @include media-breakpoint-up(lg) {
    @include font-size(24px);
  }

  &.slick-next {
    right: 0;
  }

  &.slick-prev {
    left: 0;
  }
}

