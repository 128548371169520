.tabs-list {
  @include reset-list;
  @include make-spacer(mb, 8);
  @include make-spacer(mb, 12, md);

  @include media-breakpoint-up(sm) {
    @include flex;
  }


  &__item {
    @include make-spacer(mr, 0);
    @include make-spacer(mr, 12, sm);
    @include make-spacer(mb, 2);
    @include make-spacer(mb, 0, sm);

    cursor: pointer;

    &:last-child {
      @include make-spacer(mr, 0);
    }

    &.is-active {

      .tabs-list {

        &__link {
          text-shadow: 0.1em 0.1em var(--pink);
        }
      }
    }

  }

  &__link {
    @include transition(text-shadow);
    @include font-size(18px, 25px);

    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    color: var(--black-primary);
    font-weight: var(--extra-bold);
    font-family: var(--headings-font-family);

    &:hover {
      text-shadow: 5px 5px var(--pink);
    }

    @include media-breakpoint-up(sm) {
      @include font-size(24px, 34px);

    }

    @include media-breakpoint-up(md) {
      @include font-size(48px, 67px);
    }
  }
}
