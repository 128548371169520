.contact-block {
  @include make-spacer(mb, 16);
  @include make-spacer(mb, 20, lg);

  &__title {
    @include font-size(14px, 16px);

    text-transform: uppercase;
    font-family: var(--headings-font-family);
    font-weight: var(--extra-bold);

    @include media-breakpoint-up(sm) {
      @include font-size(18px, 24px);
    }
  }

  &__ratio {
    position: relative;
    padding-top: 56.25%;
    height: 0;
    overflow: hidden;
  }

  &__map {
    @include size(100%);

    position: absolute;
    top: 0;
    left: 0;
  }

  &__text {
    @include make-spacer(mb, 6);
    @include make-spacer(mb, 0, lg);

    background-color: var(--black-secondary);

    @include media-breakpoint-up(lg) {
      grid-row: 1;
      grid-column: 1;
    }
  }

  &__content {
    @include make-spacer(mt, 12);
    @include make-spacer(mt, 0, lg);

    grid-row: 1;
    grid-column: 2 / 3;
  }

  &__list {
    @include make-spacer(px, 4);
    @include make-spacer(px, 6, lg);
    @include make-spacer(py, 6);
    @include make-spacer(py, 6);
    @include make-spacer(py, 8, sm);
    @include make-spacer(pb, 14, lg);

    font-style: normal;

    a:hover {
      color: var(--yellow);
    }
  }

  &__item {
    @include make-spacer(mb, 3);
    @include font-size(14px, 16px);

    display: block;
    color: var(--white);

    > a {
      color: var(--white);
    }

    &:last-child {
      @include make-spacer(mb, 0);
    }

    @include media-breakpoint-up(lg) {
      @include font-size(16px, 24px);
    }
  }

  &__socials {
    @include make-spacer(mt, 6);
    @include make-spacer(mt, 8, sm);

    @include media-breakpoint-up(lg) {
      grid-column: 2 / 3;
    }
  }

  &__footer {
    text-align: center;

    @include media-breakpoint-up(lg) {
      grid-row: 1;
      grid-column: 1;
      align-self: flex-end;
    }
  }

  &__button {
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: auto;
    }
  }

  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: 400px 1fr;
    grid-column-gap: 20px;
  }
}
