.games-block {

  &__image {
    @include size(100%);

    display: block;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  &__grid {
    @include make-spacer(mb, 8);

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 40px;
    grid-column-gap: 20px;

    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(12, 1fr);
    }
  }

  &__ratio {
    position: relative;
    flex-shrink: 0;
    padding-top: 175%;
  }

  &__item {

    &:nth-child(-n + 5) {
      grid-column: 1 / 3;
    }

    &:nth-child(n+4):nth-child(-n+5) {

      .games-block__ratio {
        padding-top: 56.25%;
      }
    }

    @include media-breakpoint-up(sm) {
      grid-column: auto / span 3;

      &:nth-child(-n + 3) {
        grid-column: auto / span 4;
      }

      &:nth-child(n+4):nth-child(-n+5) {
        grid-column: auto / span 6;
      }
    }
  }

  &__footer {
    text-align: center;

    @include media-breakpoint-up(sm) {
      @include flex(space-between, center);
    }
  }

  &__pagination {
    @include make-spacer(mb, 8);
    @include make-spacer(mb, 0, sm);
  }

  &--flat {

    .games-block {

      &__grid {
        @include media-breakpoint-up(sm) {
          grid-template-columns: repeat(4, 1fr);
        }

        .games-block { //stylelint-disable-line

          &__item { //stylelint-disable-line
            grid-column: auto;

            .games-block__ratio { //stylelint-disable-line
              padding-top: 175%;
            }
          }
        }
      }
    }
  }
}
