.link {
  @include flex($display: inline-flex);
  @include transition(color);
  @include font-size(12px, 18px);

  text-decoration: none;
  color: var(--yellow);
  font-family: var(--headings-font-family);
  font-weight: var(--extra-bold);

  &:hover {
    color: var(--pink);
  }

  &:active {
    color: var(--pink-active);
  }

  &__arrow {
    @include flex(center, center);
    @include make-spacer(ml, 2);

    align-self: center;
  }

  &.reset {
    color: var(--gray);
    text-transform: uppercase;

    &:hover {
      color: var(--yellow);
    }
  }

  &.disabled,// stylelint-disable-line
  &[disabled] {
    color: var(--gray);
  }

  &--primary {
    color: var(--black-primary);
  }

  &--loading {
    color: var(--white);

    .link {

      &__arrow {
        @include transition(transform);
        @include font-size(24px);
      }
    }

    &.is-active {

      .link {

        &__arrow {
          animation: spin 1s infinite linear;
        }
      }
    }
  }

  @include media-breakpoint-up(sm) {
    @include font-size(16px, 24px);
  }
}

@keyframes spin {

  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}
