.modal {
  @include size(100%);
  @include transition(#{opacity});

  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
  overflow-y: auto;

  &.modal-is-hidden {
    display: none;
  }

  &.modal-show {
    opacity: 0;
  }

  &__body {
    position: relative;
    background-color: var(--white);
    width: 100%;
    max-width: calc(100% - 32px);

    @include media-breakpoint-up(sm) {
      max-width: 75%;
    }
  }

  &__dialog {
    @include make-spacer(p, 4, sm);
    @include flex(center, center);

    max-width: 100%;
    min-height: calc(100% - (1.75rem * 2));
  }

  &__close {
    @include font-size(24px);
    @include transition(color);

    position: absolute;
    top: rem(16px);
    right: rem(16px);
    border: 0;
    background: transparent;
    color: var(--black-700);

    &::before {
      content: '\2715';
    }

    &:hover {
      cursor: pointer;
    }

    @include media-breakpoint-up(sm) {
      top: rem(48px);
      right: rem(48px);
    }
  }

  &--sm {

    .modal__body {
      @include make-spacer(pt, 10);
      @include make-spacer(px, 3);
      @include make-spacer(pb, 4);

      @include media-breakpoint-up(sm) {
        @include make-spacer(pt, 12);
        @include make-spacer(px, 12);
        @include make-spacer(pb, 8);

        max-width: rem(688px);
      }
    }
  }
}
