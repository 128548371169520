.u-bg-black-primary {
  background-color: var(--black-primary);
}

.u-bg-black-secondary {
  background-color: var(--black-secondary);
}

.u-bg-gray-100 {
  background-color: var(--gray-100);
}

.u-bg-white {
  background-color: var(--white);
}

.u-back-pink-hover {
  width: inherit;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: var(--pink);

  &__wrapper {
    @include size(100%);
    @include transition(transform);
  }

  &:hover {
    .u-back-pink-hover__wrapper {
      transform: translate(-10px, -10px);
    }
  }
}
