
*,
::after,
::before {
  box-sizing: border-box;
}

body {
  @include smooth-font;
  @include make-spacer(mx, auto);
  @include make-spacer(my, 0);
  @include flex($direction: column);

  min-width: 320px;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  line-height: var(--line-height);
  color: var(--black-text);
  font-family: var(--font-family);
  font-weight: var(--regular);
  font-feature-settings: 'pnum' on, 'lnum' on;

  &.is-overflow-hidden {
    overflow: hidden;
  }
}

.main-container {
  @include flex($direction: column);

  flex-grow: 1;
}

@keyframes fade-in {

  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

//
// Headings
//

@include make-all-type($tag-types);

@for $i from 1 through 6 {

  h#{$i},
  .h#{$i} {
    @include make-spacer(mx, 0);
    @include make-spacer(mt, 0);
    @include make-spacer(mb, 3);

    text-transform: uppercase;
    line-height: var(--headings-line-height);
    font-family: var(--headings-font-family);
    font-weight: var(--extra-bold);
  }
}

p {
  @include make-spacer(mx, 0);
  @include make-spacer(mt, 0);
  @include make-spacer(mb, 3);

  &:last-child {
    @include make-spacer(mb, 0);
  }
}

button {
  @include reset-button;
}

a {
  @include transition(color);

  text-decoration: none;
  line-height: var(--line-height);
  color: var(--pink);
  font-size: var(--font-size);

  &:hover {
    text-decoration: none;
  }
}

ul {
  @include reset-list;
}

.is-overflow-hidden {
  overflow: hidden;
}
