.main-menu {
  @include size(100%, calc(100% - var(--header-height)));

  display: block;
  position: fixed;
  top: var(--header-height);
  right: 0;
  bottom: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  z-index: map-get($z-indexes, main-nav);
  background-color: var(--black-primary-alpha-04);

  &__wrapper {
    @include size(100%);
    @include flex($direction: column);

    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(100%);
    background-color: var(--black-primary);
    max-width: 500px;
  }

  &__top {
    @include flex(space-between);
    @include make-spacer(px, 3);
    @include make-spacer(py, 6);

    border-bottom: 1px solid var(--black-700);
    font-size: 12px;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  &__bottom {
    @include flex(space-between, center);
    @include make-spacer(px, 3);
    @include make-spacer(py, 6);
    @include make-spacer(pb, 20, sm);
    @include make-spacer(pb, 12, md);
    @include make-spacer(px, 8, sm);

    border-top: 1px solid var(--black-700);

    @include media-breakpoint-up(sm) {
      border-top: 0;
    }
  }

  &__contact {
    @include font-size(14px);

    text-decoration: none;
    color: var(--white);

    &:hover {
      color: var(--yellow);
    }

    @include media-breakpoint-up(sm) {
      @include font-size(16px);

      font-weight: var(--bold);
    }
  }
}
