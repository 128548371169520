.slider-pagination {
  display: inline-grid;
  grid-auto-flow: column;
  color: var(--black-primary);
  font-family: var(--headings-font-family);
  font-size: 16px;
  font-weight: var(--extra-bold);

  &__counter {
    display: grid;
    grid-template-columns: 15px 4px 15px;
    grid-column-gap: 8px;
    align-items: center;

    > :first-child {
      text-align: right;
    }

    > :last-child {
      text-align: left;
    }

    @include media-breakpoint-up(sm) {
      grid-template-columns: 30px 4px 30px;
    }
  }

  &__arrow {
    position: static;
    transform: none;

    &.slick-disabled {

      .icon {
        fill: var(--gray);
      }
    }

    .icon {
      @include transition(fill);
      @include font-size(24px);
    }

    &:hover {

      .icon {
        fill: var(--pink);
      }
    }

    &--right {
      @include make-spacer(pl, 4);
    }

    &--left {
      @include make-spacer(pr, 4);
    }
  }

  &--white {
    color: var(--white);
  }
}
