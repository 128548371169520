.similar {

  &__item {
    @include make-spacer(mb, 4);

    width: 100%;

    &:last-child {
      @include make-spacer(mb, 0);
    }
  }

  &__wrap {
    @include make-spacer(px, 4);
    @include make-spacer(px, 6, sm);
    @include make-spacer(py, 6);
    @include make-spacer(py, 8, sm);
  }

  &__district {
    @include font-size(14px, 20px);
    @include make-spacer(mb, 4);

    color: var(--gray);
  }

  &__title {
    @include make-spacer(mb, 5);

    font-family: var(--headings-font-family);
    font-weight: var(--bold);
  }

  &__link {
    @include font-size(12px, 16px);

    text-transform: uppercase;
    color: var(--black-primary);
    font-family: var(--headings-font-family);
    font-weight: var(--extra-bold);
  }

  @include media-breakpoint-up(sm) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr;
  }
}
