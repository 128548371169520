.cookie-policy {
  @include transition(transform);

  position: fixed;
  bottom: 0;
  left: 0;
  background-color: var(--black-primary-alpha-08);
  width: 100%;

  &:not(.is-active) {
    transform: translateY(100%);
  }

  &__container {
    @include make-spacer(py, 5);
    @include make-spacer(py, 8, sm);

    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: 25px;
    align-items: center;
  }

  &__text {
    @include font-size(12px, 16px);

    color: var(--white);

    a {
      @include font-size(12px, 16px);

      color: var(--yellow);

      &:hover {
        text-decoration: underline;
      }

      @include media-breakpoint-up(sm) {
        @include font-size(14px, 24px);
      }
    }

    @include media-breakpoint-up(sm) {
      @include font-size(14px, 24px);
    }
  }

  &__icon {
    @include font-size(30px);

    fill: var(--white);

    @include media-breakpoint-up(md) {
      @include font-size(24px);
    }
  }
}
