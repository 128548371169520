.dropdown {
  position: relative;
  color: var(--white);
  z-index: map-get($z-indexes, dropdown);

  &__trigger {
    @include flex($align-items: center);

    color: var(--white);
    font-family: var(--headings-font-family);
    font-weight: var(--bold);

    &.is-open {

      + .dropdown__list {
        transform: translateY(0) translateX(-50%);
        visibility: visible;
        opacity: 1;
      }

      .dropdown__arrow {
        transform: rotateZ(-180deg);
      }
    }
  }

  &__arrow {
    @include transition(transform);

    transform: rotateZ(0deg);
    transform-origin: center;
    pointer-events: none;
  }

  &__label {
    @include make-spacer(mr, 2);

    font-family: var(--headings-font-family);
    pointer-events: none;
    text-transform: uppercase;
  }

  &__list {
    @include make-spacer(py, 3);
    @include transition(#{visibility, transform, opacity});

    position: absolute;
    top: rem(32px);
    left: 50%;
    transform: translateY(-15px) translateX(-50%);
    visibility: hidden;
    opacity: 0;
    z-index: 3;
    background-color: var(--white);
    width: auto;
  }

  &__button {
    @include font-size(12px);
    @include make-spacer(px, 3);
    @include make-spacer(py, 1);
    @include transition(color);

    display: block;
    text-align: left;
    text-decoration: none;
    color: var(--black-primary);
    font-family: var(--headings-font-family);
    white-space: nowrap;
    text-transform: uppercase;

    &:hover {
      color: var(--pink);
    }
  }

  &--extra-bold {

    .dropdown__label {
      font-weight: var(--extra-bold);
    }
  }
}
