.radio-button {
  cursor: pointer;
  user-select: none;


  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 0;
    height: 0;

    &:checked {

      + .radio-button {

        &__text {

          &::before {// stylelint-disable-line
            transform: scale(1);
          }

          &::after {// stylelint-disable-line
            border-color: var(--pink);
          }
        }
      }
    }
  }

  &__text {
    @include make-spacer(pl, 6);
    @include font-size(14px, 16px);

    display: block;
    position: relative;

    &::after {
      @include transition(border-color);
      @include size(20px);

      top: -2px;
      left: 0;
      border: 2px solid var(--white);
    }

    &::before {
      @include transition(transform);
      @include size(10px);

      top: 3px;
      left: 5px;
      transform: scale(0);
      background-color: var(--pink);
    }

    &::after,
    &::before {
      position: absolute;
      border-radius: 50%;
      content: '';
    }
  }
}
