.js-animate-fade-in {
  @include transition(#{transform, opacity});

  transform: translateY(20px);
  opacity: 0;

  &.js-animated {
    transform: translateY(0);
    opacity: 1;
  }
}
