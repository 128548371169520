.ul {
  @include reset-list;

  li {
    @include make-spacer(pl, 4);
    @include make-spacer(pl, 5, sm);
    @include make-spacer(pl, 6, md);
    @include make-spacer(mb, 3);
    @include make-spacer(mb, 4, md);
    @include font-size(14px, 20px);

    position: relative;

    &::before {
      @include size(10px);

      position: absolute;
      top: 4px;
      left: 0;
      background-color: var(--pink);
      content: '';
    }

    &:last-child {
      @include make-spacer(mb, 0);
    }

    @include media-breakpoint-up(md) {
      @include font-size(16px, 24px);

      &::before {
        top: 7px;
      }
    }
  }
}

.ol {
  @include reset-list;

  li {
    @include make-spacer(pl, 4);
    @include make-spacer(pl, 5, sm);
    @include make-spacer(pl, 6, md);
    @include make-spacer(mb, 3);
    @include make-spacer(mb, 4, md);
    @include font-size(14px, 20px);

    position: relative;
    counter-increment: li;

    &::before {
      @include make-spacer(mr, 2);

      position: absolute;
      top: 0;
      left: 0;
      color: var(--pink);
      font-weight: var(--bold);
      content: counter(li);
    }

    &:last-child {
      @include make-spacer(mb, 0);
    }

    @include media-breakpoint-up(md) {
      @include font-size(16px, 24px);
    }
  }
}
