.container {
  @include make-spacer(mx, auto);

  width: 100%;

  @include media-breakpoint-up(md) {
    max-width: var(--container-width);
  }

  // Modifier

  &.content {
    @include make-spacer(px, 3);
    @include make-spacer(px, 8, sm);
    @include make-spacer(px, 20, lg);
  }

  &.fluid {
    @include media-breakpoint-up(md) {
      max-width: none;
    }
  }
}
