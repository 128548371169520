.map-schema {
  @include make-spacer(pt, 12);
  @include make-spacer(pt, 30, lg);

  &__image {

    img {
      @include make-spacer(mx, auto);

      display: block;
      max-width: 100%;
      height: auto;
      max-height: 556px;
    }
  }
}
