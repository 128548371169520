// Spacing
//
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 10px !default;
$spacers: (
  auto: auto,
  0: 0,
  half: $spacer * 0.25,
  1: $spacer * 0.5,
  2: $spacer,
  3: $spacer * 1.5,
  4: $spacer * 2,
  5: $spacer * 2.5,
  6: $spacer * 3,
  7: $spacer * 3.5,
  8: $spacer * 4,
  9: $spacer * 4.5,
  10: $spacer * 5,
  11: $spacer * 5.5,
  12: $spacer * 6,
  13: $spacer * 6.5,
  14: $spacer * 7,
  15: $spacer * 7.5,
  16: $spacer * 8,
  17: $spacer * 8.5,
  18: $spacer * 9,
  19: $spacer * 9.5,
  20: $spacer * 10,
  21: $spacer * 10.5,
  22: $spacer * 11,
  23: $spacer * 11.5,
  24: $spacer * 12,
  25: $spacer * 12.5,
  26: $spacer * 13,
  27: $spacer * 13.5,
  28: $spacer * 14,
  29: $spacer * 14.5,
  30: $spacer * 15,
  31: $spacer * 15.5,
  32: $spacer * 16,
  33: $spacer * 16.5,
  34: $spacer * 17,
  35: $spacer * 17.5,
  36: $spacer * 18,
  37: $spacer * 18.5,
  38: $spacer * 19,
  39: $spacer * 19.5,
  40: $spacer * 20,
);
