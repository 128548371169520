.news-grid {
  display: grid;
  row-gap: 35px;

  @include media-breakpoint-up(sm) {
    column-gap: 20px;
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
