.hero {
  @include flex(center, center, column);
  @include make-spacer(py, 6);

  background-color: var(--black-secondary);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(100vh - var(--header-height));
  overflow: hidden;
  color: var(--white);

  &__container {
    @include flex(center, flex-start, column);

    flex-grow: 1;
  }

  &__title {
    @include make-spacer(mb, 4);
    @include make-spacer(mb, 6, sm);
    @include make-spacer(mb, 8, md);

    &--pink {
      text-shadow: 0.1em 0.1em var(--pink);
    }
  }

  &__tel {
    @include make-spacer(mb, 0);
  }

  &__text {
    @include make-spacer(mb, 8);
    @include font-size(14px, 20px);

    @include media-breakpoint-up(sm) {
      @include font-size(16px, 24px);

      width: 472px;
    }

    @include media-breakpoint-up(md) {
      width: 640px;
    }
  }

  &__link {
    color: var(--white);

    &:hover {
      color: var(--yellow);
    }
  }

  &__wrapper {
    display: none;

    @include media-breakpoint-up(sm) {
      @include flex(space-between, center);
      @include make-spacer(mx, 8);
      @include make-spacer(mb, 7);

      color: var(--white);
    }
  }

  &__buttons {
    display: grid;
    grid-row-gap: 20px;
    width: 100%;

    @include media-breakpoint-up(sm) {
      grid-auto-flow: column;
      grid-column-gap: 40px;
      width: auto;
    }

    @include media-breakpoint-up(md) {
      grid-column-gap: 50px;
    }
  }

  &--center {

    .hero {

      &__container {
        align-items: center;
        text-align: center;
      }
    }
  }
}


