.game-zone-section {
  width: 100%;

  &__item {
    @include make-spacer(mb, 0);
    @include make-spacer(mb, 20, md);

    display: none;

    &.is-active {
      display: block;
    }

    @include media-breakpoint-up(md) {
      display: flex;
    }
  }

  &__body {
    display: grid;

    @include media-breakpoint-up(md) {
      width: 100%;
      grid-template-columns: 400px 1fr 1fr;
      grid-row-gap: 60px;
      grid-column-gap: 20px;
    }
  }

  &__discipline {
    @include flex;
  }

  &__wrapper {
    @include flex(space-between, $direction: column);
    @include make-spacer(px, 4);
    @include make-spacer(px, 6, md);
    @include make-spacer(py, 6);
    @include make-spacer(py, 8, md);
    @include make-spacer(mb, 8);
    @include make-spacer(mb, 0, md);

    background-color: var(--white);

    @include media-breakpoint-up(md) {
      min-height: 470px;
    }
  }

  &__title {
    @include font-size(18px, 25px);

    text-transform: uppercase;
    font-family: var(--headings-font-family);
    font-weight: var(--extra-bold);
  }

  &__text {
    @include make-spacer(pl, 4);
    @include make-spacer(pl, 5, sm);
    @include make-spacer(pl, 6, md);
    @include make-spacer(mb, 3);
    @include make-spacer(mb, 4, md);
    @include font-size(12px, 20px);

    position: relative;

    &::before {
      @include size(10px);

      position: absolute;
      top: 4px;
      left: 0;
      background-color: var(--pink);
      content: '';
    }

    &:last-child {
      @include make-spacer(mb, 0);
    }

    @include media-breakpoint-up(md) {
      @include font-size(16px, 24px);

      &::before {
        top: 7px;
      }
    }
  }

  &__slider {
    @include make-spacer(mb, 9);
    @include make-spacer(mb, 0, md);

    position: relative;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      grid-column: 2 / 4;
    }
  }

  &__tabs {
    @include make-spacer(mt, 0);

    display: flex;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__link {
    @include flex(center);
    @include make-spacer(mb, 12);
    @include make-spacer(mb, 0, md);

    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      grid-row: 2;
      grid-column: 3 / 4;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &__pagination {
    text-align: center;

    @include media-breakpoint-up(md) {
      grid-row: 2;
      grid-column: 2 / 3;
      text-align: left;
    }
  }
}
