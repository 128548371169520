.checkbox {
  cursor: pointer;
  user-select: none;

  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 0;
    height: 0;

    &:checked {

      + .checkbox {

        &__text {

          &::before {
            transform: scale(1);
          }
        }
      }
    }

    &:disabled {
      pointer-events: none;

      + .checkbox {

        &__text {
          color: rgba(35, 35, 35, 0.4);

          &::after {
            border-color: rgba(0, 0, 0, 0.15);
          }
        }
      }
    }
  }

  &__text {
    @include make-spacer(pl, 6);
    @include font-size(14px, 16px);

    display: block;
    position: relative;
    padding-top: 4px;

    &::after {
      @include transition(border-color);
      @include size(22px);

      top: 0;
      left: 0;
      border: 1px solid var(--black-800);
      content: '';
    }

    &::before {
      @include transition(transform);
      @include size(14px);

      top: 4px;
      left: 4px;
      background-color: var(--yellow);
      transform: scale(0);
      content: '';
    }

    &::after,
    &::before {
      position: absolute;
      border-radius: 2px;
    }
  }

  &--md {

    .checkbox {

      &__text {
        @include font-size(16px);
      }
    }
  }

  &--light {

    .checkbox {

      &__text {

        &::after {
          border-color: var(--white);
        }
      }
    }
  }
}
