.header {
  @include size(100%, var(--header-height));
  @include font-size(12px);
  @include make-spacer(px, 3);
  @include make-spacer(px, 8, md);

  display: grid;
  position: sticky;
  top: 0;
  left: 0;
  grid-template-columns: 58px 1fr auto;
  grid-column-gap: 25px;
  align-items: center;
  z-index: map-get($z-indexes, header);
  background-color: var(--black-secondary);
  font-family: var(--headings-font-family);
  text-transform: uppercase;

  @include media-breakpoint-up(sm) {
    grid-template-columns: 84px 1fr auto;
    grid-column-gap: 30px;
  }

  @include media-breakpoint-up(xl) {
    grid-column-gap: 40px;
  }

  &__logo {
    display: block;

    img {
      @include size(100%);

      display: block;
      object-fit: contain;
    }
  }

  &__location,
  &__nav,
  &__language {
    display: none;
  }

  &__link {
    @include make-spacer(ml, auto, sm);
    @include make-spacer(mr, 6, sm);
    @include make-spacer(mr, 8, xl);

    font-size: 10px;

    @include media-breakpoint-up(sm) {
      font-size: 12px;
    }
  }

  &__location {
    @include media-breakpoint-up(sm) {
      color: red;
      display: block;
    }
  }

  &__language {
    @include media-breakpoint-up(sm) {
      color: red;
      display: block;
    }
  }

  &__nav {
    @include make-spacer(ml, 8, xl);

    @include media-breakpoint-up(xl) {
      display: block;
    }
  }

  &__container {
    @include flex(center, center);
  }

  &__controls {
    @include flex(center, center);
    @include make-spacer(pt, 1);

    &.is-disabled {
      pointer-events: none;
    }

    &:focus {
      outline: none;
    }
  }
}
