.article-socials {
  @include flex;

  border: 1px solid var(--gray-100);
  background-color: var(--white);

  &__item {
    flex-grow: 1;
    height: 48px;

    &:not( :last-child ) {
      border-right: 1px solid var(--gray-100);
    }

    @include media-breakpoint-up(md) {
      height: 58px;
    }
  }

  &__link {
    @include size(100%);
    @include flex(center, center);

    color: var(--black-text);

    &:hover {
      color: var(--pink);
    }
  }

  &__icon {
    @include font-size(16px);
    @include size(16px);

    display: block;

    img {
      @include size(100%);

      display: block;
      object-fit: contain;
    }

    @include media-breakpoint-up(md) {
      @include font-size(24px);
      @include size(24px);
    }
  }
}
