.scroll-top-button {
  @include size(rem(56px));
  @include flex(center, center);

  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: map-get($z-indexes, scroll-to-top-button);

  &__icon {
    transform: rotateZ(-90deg);
    color: var(--white);
    pointer-events: none;
  }
}
