.section {
  @include make-spacer(py, 16);
  @include make-spacer(py, 20, sm);
  @include make-spacer(py, 30, md);

  &__title {
    @include make-spacer(mb, 8);
    @include make-spacer(mb, 12, lg);
  }
}
