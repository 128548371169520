/* ==========================================================================
   #DISPLAY: NONE
   ========================================================================== */

.u-hidden {
  display: none !important;
}

@include media-breakpoint-down(xs) {

  .u-hidden-sm {
    display: none !important;
  }
}

@include media-breakpoint-down(sm) {

  .u-hidden-md {
    display: none !important;
  }
}

@include media-breakpoint-down(md) {

  .u-hidden-lg {
    display: none !important;
  }
}

@include media-breakpoint-down(lg) {

  .u-hidden-xl {
    display: none !important;
  }
}
