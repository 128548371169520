.copy-service-section {

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;

    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 40px 20px;
    }

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__title {
    @include make-spacer(p, 4);
    @include make-spacer(pr, 12);
    @include make-spacer(mb, 0);

    @include media-breakpoint-up(sm) {
      @include make-spacer(p, 6);
      @include make-spacer(pt, 8);
      @include font-size(18px, 22px);

      height: 115px;
    }

    @include media-breakpoint-up(md) {
      @include font-size(24px, 34px);

      height: 135px;
    }
  }

  &__block {
    @include make-spacer(p, 4);

    display: none;
    border-top: 1px solid var(--black-700);
    font-family: var(--headings-font-family);
    font-weight: var(--bold);

    @include media-breakpoint-up(sm) {
      @include make-spacer(p, 6);

      display: block;
    }
  }

  &__item {
    position: relative;

    &::after {
      @include font-size(24px);

      position: absolute;
      top: 15px;
      right: 20px;
      content: '\002B';
    }

    &.is-open {

      &::after {
        @include font-size(24px);

        content: '\2212';
      }

      @include media-breakpoint-up(sm) {

        &::after {
          content: '';
        }
      }
    }

    @include media-breakpoint-up(sm) {

      &::after {
        content: '';
      }
    }
  }
}
