.vr-cta {

  &__item {
    display: block;
    width: 288px;
    font-family: var(--headings-font-family);

    @include media-breakpoint-up(sm) {
      width: 390px;
    }

    @include media-breakpoint-up(md) {
      width: 100%;
    }
  }

  &__header {
    @include make-spacer(pt, 7);
    @include make-spacer(pb, 6);
    @include make-spacer(px, 4);

    border-bottom: 1px solid var(--black-700);
  }

  &__title {
    text-transform: uppercase;
  }

  &__icon {
    margin-right: 10px;
  }

  &__time {
    @include flex($align-items: center);

    color: var(--yellow);
    font-weight: var(--extra-bold);
  }

  &__body {
    @include make-spacer(py, 6);
    @include make-spacer(px, 4);

    border-bottom: 1px solid var(--black-700);
  }

  &__subheader {
    @include make-spacer(mb, 3);

    color: var(--gray);
  }

  &__price {
    @include make-spacer(mb, 3);
    @include flex($align-items: flex-end);
  }

  &__tariff {
    @include font-size(24px);

    font-weight: var(--extra-bold);

    @include media-breakpoint-up(sm) {
      @include font-size(48px);
    }
  }

  &__per {
    @include font-size(14px);

    font-weight: var(--bold);
  }

  &__divider {
    @include size(1px, 30px);
    @include make-spacer(mx, 3);

    transform: rotateZ(26deg);
    background-color: var(--white);
  }

  &__tip {
    @include font-size(14px);

    color: rgba(255, 255, 255, 0.4);
    font-family: var(--font-family);
  }

  &__footer {
    @include flex(center, center);

    height: 70px;

    @include media-breakpoint-up(sm) {
      height: 100px;
    }
  }

  &__content {
    display: grid;
    position: relative;
    margin: 0 -15px;
    padding-top: 10px;
    padding-bottom: 60px;
    overflow-y: auto;

    @include media-breakpoint-only(sm) {
      margin: 0 -40px;
    }
  }

  &__wrapper {
    @include make-spacer(px, 3);
  }

  &__grid {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 20px;
  }
}
