.contacts-form {
  max-width: 820px;

  &__grid {
    display: grid;
    grid-row-gap: 5px;

    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 15px;
      grid-column-gap: 20px;
      align-items: flex-start;
    }
  }

  &__footer {
    @include media-breakpoint-up(sm) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column: 1 / 3;
      grid-column-gap: 20px;
    }

    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr 400px;
    }
  }

  &__checkbox {
    @include make-spacer(mb, 8);

    @include media-breakpoint-up(sm) {
      grid-column: 1 / 3;
    }

    @include media-breakpoint-up(md) {
      grid-column: 1 / 2;
    }
  }

  &__button {
    width: 100%;
  }

  &__textarea {
    @include make-spacer(mb, 4);

    @include media-breakpoint-up(sm) {
      grid-column: 1 / 3;
    }
  }

  &__reset {
    @include make-spacer(mt, 4);
    @include make-spacer(mt, 0, sm);
    @include flex(center, center);
    @include size(100%);

    @include media-breakpoint-up(sm) {
      justify-content: flex-start;
      order: -1;
    }
  }
}
