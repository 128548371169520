.vr-success {
  @include make-spacer(py, 6);

  @include media-breakpoint-up(sm) {
    @include flex(center, center);
    min-height: 712px;
  }

  &__title {
    @include make-spacer(mb, 3);
    @include make-spacer(mx, auto);

    max-width: 520px;
  }

  &__subtitle {
    @include font-size(14px, 20px);
    @include make-spacer(mx, auto);

    max-width: 340px;

    @include media-breakpoint-up(sm) {
      @include font-size(16px, 24px);
    }
  }

  &__button {
    @include make-spacer(mt, 2);
    @include make-spacer(mt, 7, sm);
  }
}
