.socials {
  @include reset-list;
  @include flex(space-between, center);

  &__link {
    @include flex(center, center);

    color: var(--white);

    img {
      @include size(100%);

      display: block;
      object-fit: contain;
    }

    &:hover {
      color: var(--pink);

      .socials {

        &__icon {
          fill: var(--yellow);
        }
      }
    }
  }

  &__icon {
    @include size(16px);
    @include font-size(16px);
    @include transition(fill);

    fill: var(--white);

    @include media-breakpoint-up(lg) {
      @include font-size(24px);
      @include size(24px);
    }
  }

  &__text {
    @include make-spacer(mr, 4);
    @include font-size(12px, 16px);

    text-transform: uppercase;
    font-family: var(--headings-font-family);
    font-weight: var(--extra-bold);

    @include media-breakpoint-up(lg) {
      @include font-size(16px, 24px);
    }
  }

  &__item {

    &:not(:first-of-type) {
      @include make-spacer(ml, 7);
    }
  }

  &--titled {

    .socials {

      &__icon {
        fill: var(--yellow);
      }

      &__item {
        flex-grow: 1;
      }

      &__link {
        min-height: 50px;

        @include media-breakpoint-up(lg) {
          min-height: 60px;
        }
      }
    }
  }
}
