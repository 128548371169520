$base-spacer: 5;
$spacings: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95,100);
$directions: ('mt', 'mr', 'mb', 'ml', 'mx', 'my', 'pt', 'pr', 'pb', 'pl', 'px', 'py');

@mixin set-spacing($spacing, $direction, $breakpoint: null) {
  @if ($breakpoint != null and $breakpoint != xs) {

    .u-#{$direction}-#{$spacing}-#{$breakpoint} {
      @include make-spacer($direction, ($spacing / $base-spacer), $breakpoint);
    }
  }

  @else {

    .u-#{$direction}-#{$spacing} {
      @include make-spacer($direction, ($spacing / $base-spacer));
    }
  }
}

@each $spacing in $spacings {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @each $direction in $directions {
      @include set-spacing($spacing, $direction, $breakpoint);
    }
  }
}

.u-mx-auto {
  margin-left: auto;
  margin-right: auto;
}
