.vr-calendar {
  @include media-breakpoint-up(md) {
    background-color: var(--black-secondary);
    padding: 100px 0;
  }

  &__body {
    @include make-spacer(mx, auto);

    max-width: rem(674px);
  }

  &__tabs {
    margin-bottom: 20px;

    @include media-breakpoint-up(sm) {
      margin-bottom: 30px;
    }
  }
}
