.vr-form {

  &__title {
    @include font-size(24px, 32px);
    @include make-spacer(mb, 5);
    @include make-spacer(mb, 7, sm);

    font-family: var(--headings-font-family);
    font-weight: var(--extra-bold);
    text-transform: uppercase;
  }

  &__label {
    @include font-size(14px);
    @include make-spacer(mb, 2);
    @include make-spacer(mb, 3, sm);

    @include media-breakpoint-up(sm) {
      @include font-size(16px);
    }
  }

  &__list {
    @include make-spacer(p, 4);
    @include make-spacer(mb, 6);

    position: relative;
    border: 1px solid var(--gray-200);

    &::after,
    &::before {
      display: block;
      position: absolute;
      top: 16px;
      bottom: 42px;
      width: 32px;
      z-index: 1;
      content: '';
    }

    &::after {
      right: 16px;
      background-image: linear-gradient(270deg, var(--white) 25%, transparent 100%);

    }

    &::before {
      @include transition(opacity);

      left: 16px;
      background-image: linear-gradient(90deg, var(--white) 25%, transparent 100%);
      opacity: 0;
    }

    &.has-left-shadow {

      &::before {
        opacity: 1;
      }
    }
  }

  &__grid {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    grid-template-columns: repeat(5, 230px);
    grid-column-gap: 10px;
    grid-auto-flow: column;
  }

  &__overflow {
    @include make-spacer(pb, 2);

    overflow-x: auto;

    &::-webkit-scrollbar-track {
      background-color: var(--black-primary-alpha-016);
    }

    &::-webkit-scrollbar {
      @include size(4px);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--black-text);
    }
  }

  &__checkbox {
    width: 100%;
    margin-bottom: 22px;
  }

  &__switcher {
    @include make-spacer(mb, 6);
    @include make-spacer(mb, 9, sm);

    display: inline-flex;
    height: 45px;
    border: 1px solid var(--gray-200);
  }

  &__count {
    @include flex(center, center);
    @include font-size(16px);
    @include make-spacer(m, 0);
    @include make-spacer(p, 0);

    text-align: center;
    border: 0;
    background-color: transparent;
    max-width: 60px;
    box-shadow: none;

    &:focus {
      outline: none;
    }
  }

  &__button {
    @include flex(center, center);
    @include size(45px);
    @include font-size(24px);

    &:active {
      background-color: var(--gray-200);
    }

    &--inc {
      border-left: 1px solid var(--gray-200)
    }

    &--dec {
      border-right: 1px solid var(--gray-200)
    }
  }

  &__total {
    @include font-size(14px);

    @include media-breakpoint-up(sm) {
      @include font-size(16px);
    }
  }

  &__submit {
    @include make-spacer(my, 4);
    @include make-spacer(my, 6, sm);

    .is-preloaded {
      cursor: none;
      pointer-events: none;

      .vr-form__preloader {
        display: block;
      }

      .icon {
        display: none;
      }
    }
  }

  &__contacts {
    @include media-breakpoint-up(sm) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
    }
  }

  &__preloader {
    @include size(30px);

    display: none;
  }
}
