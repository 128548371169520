.game-zone-slider {
  @include size(100%);

  .slick-list,
  .slick-track,
  .slick-slide > div {
    height: 100%;
  }

  &__image {
    @include size(100%);

    display: block;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  &__ratio {
    position: relative;
    padding-top: 56.25%;
    height: 0;
    min-height: 100%;
    overflow: hidden;
    vertical-align: middle;
  }
}
