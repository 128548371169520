.input {
  @include font-size(16px, 24px);
  @include make-spacer(px, 4);
  @include make-spacer(py, 2);
  @include make-spacer(py, 3, lg);
  @include transition(border-color);
  @include size(100%, 48px);

  border: 1px solid var(--gray-200);
  background-color: var(--white);

  &::placeholder {
    @include transition(#{opacity, text-indent});
    @include font-size(16px,24px);

    color: var(--black-primary);
  }

  &.has-error {
    border-color: var(--red);
    color: var(--red);

    &:focus {
      box-shadow: none;
    }
  }

  &:focus {
    outline: none;
    border-color: var(--yellow);
    box-shadow: inset 0 0 0 2px var(--yellow);

    &::placeholder {
      opacity: 0;
      text-indent: 10px;
    }
  }

  &__icon {
    @include transition(#{opacity, visibility});

    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);

    @include media-breakpoint-up(md) {
      @include size(24px);
    }
  }

  &__reset {
    @include size(24px);
    @include flex(center, center);
    @include transition(#{opacity, visibility});

    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    visibility: hidden;
    opacity: 0;
    z-index: 2;
  }

  @include media-breakpoint-up(lg) {
    height: 58px;
  }

  &--textarea {
    @include make-spacer(py, 4);

    height: 100px;
    resize: none;

    @include media-breakpoint-up(sm) {
      height: 120px;
    }
  }

  &--file {
    @include make-spacer(p, 0);

    position: relative;
    border-color: transparent;

    [type='file'] {
      @include size(100%);

      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;

      &:focus {

        + .input__label {
          border-color: var(--yellow);
          box-shadow: inset 0 0 0 2px var(--yellow);
        }
      }
    }

    .input {

      &__icon {
        @include size(16px);

        @include media-breakpoint-up(lg) {
          @include size(24px);
        }
      }

      &__label {
        @include make-spacer(px, 4);
        @include make-spacer(py, 2);
        @include make-spacer(py, 3, lg);
        @include transition(#{border-color, box-shadow});
        @include size(100%, 48px);

        display: block;
        border: 1px solid var(--gray);
        cursor: pointer;
        overflow: hidden;

        @include media-breakpoint-up(lg) {
          height: 58px;
        }
      }

      &__placeholder {
        @include transition(#{transform, opacity});
        @include font-size(12px, 28px);

        display: block;
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        max-width: calc(100% - 60px);
        overflow: hidden;
        text-transform: uppercase;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: var(--headings-font-family);
        font-weight: var(--extra-bold);

        &--hidden {
          transform: translateY(50%);
          opacity: 0;
        }
      }
    }

    &.is-uploaded {

      .input {

        &__placeholder {
          transform: translateY(-100%);
          opacity: 0;

          &--hidden { // stylelint-disable-line
            transform: translateY(-50%);
            opacity: 1;
          }
        }

        &__reset {
          visibility: visible;
          opacity: 1;
        }

        &__icon {
          visibility: hidden;
          opacity: 0;
        }
      }
    }
  }

  &--dark {
    border-color: var(--black-700);
    background-color: var(--black-secondary);
    color: var(--white);

    &::placeholder {
      color: var(--gray);
    }

    .input {

      &__label {
        border-color: var(--black-700);
        background-color: var(--black-secondary);
        color: var(--white);
      }
    }
  }
}
