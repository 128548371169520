:root {
  --header-height: 50px;
  --container-width: 100%;

  @include media-breakpoint-up(sm) {
    --header-height: 80px;
  }

  @include media-breakpoint-up(lg) {
    --container-width: 1440px;
  }
}
