.about-us-section {
  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: 400px 1fr;
    grid-gap: 100px;
  }

  &__title {
    @include make-spacer(mb, 4);
    @include make-spacer(mb, 6, lg);
  }

  &__content {
    @include make-spacer(py, 6);
    @include make-spacer(px, 4);
    @include make-spacer(px, 6, md);
    @include make-spacer(mb, 8);
    @include make-spacer(mb, 0, lg);
  }

  &__grid {

    @include media-breakpoint-up(sm) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      position: relative;

      &::after,
      &::before {
        position: absolute;
        display: block;
        background-color: var(--black-700);
        content: '';
      }

      &::after {
        @include size(100%, 1px);

        top: 50%;
        transform: translateY(-50%);
      }

      &::before {
        @include size(1px, 100%);

        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &__item {
    @include make-spacer(py, 4);
    @include make-spacer(py, 8, sm);
    @include make-spacer(py, 9, xl);
    @include make-spacer(px, 12, sm);
    @include make-spacer(px, 19, xl);

    display: grid;
    grid-template-columns: 60px 1fr;
    grid-column-gap: 10px;
    border-bottom: 1px solid var(--black-700);

    @include media-breakpoint-up(sm) {
      display: block;
      border-bottom: 0;
    }
  }

  &__digit {
    @include font-size(24px);
    @include make-spacer(mb, 4, sm);

    font-family: var(--headings-font-family);
    font-weight: var(--extra-bold);
    color: var(--yellow);
    align-self: center;

    @include media-breakpoint-up(sm) {
      @include font-size(48px);
    }
  }
}
