:root {
  --font-family: #{$regular-fonts-list};
  --headings-font-family: #{$heading-fonts-list};
  --light: #{$fw-light};
  --regular: #{$fw-base};
  --bold: #{$fw-bold};
  --extra-bold: #{$fw-headings};
  --font-size: #{$fs-base};
  --line-height: #{$lh-base};
  --headings-line-height: #{$lh-headings};
}
