.news-card {
  @include size(100%);

  display: grid;

  &__content {
    position: relative;
    background-color: var(--white);
    color: var(--black-text);
  }

  &__link {
    @include size(100%);

    display: block;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
  }

  &__image {
    position: relative;
    padding-top: 56.25%;
    height: 0;
    min-height: 100%;
    overflow: hidden;

    img {
      @include size(100%);

      display: block;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }

  &__wrapper {
    @include font-size(14px);
    @include make-spacer(px, 4);
    @include make-spacer(py, 6);
  }

  &__title {
    @include make-spacer(mb, 3);

    display: grid;
    line-height: 24px;

    @include media-breakpoint-up(sm) {
      height: 45px;
      overflow: hidden;
    }

    @include media-breakpoint-up(md) {
      height: 75px;
      overflow: hidden;
    }

    @include media-breakpoint-up(lg) {
      @include font-size(18px, 25px);
    }
  }

  &__read {
    @include font-size(12px);

    @include media-breakpoint-up(lg) {
      @include font-size(16px);
    }
  }

  &__title,// stylelint-disable-line
  &__read {
    text-transform: uppercase;
    font-family: var(--headings-font-family);
    font-weight: var(--extra-bold);
  }

  &__text {
    @include make-spacer(mb, 5);

    @include media-breakpoint-up(sm) {
      height: 60px;
      overflow: hidden;
    }

    @include media-breakpoint-up(sm) {
      height: 75px;
    }

    @include media-breakpoint-up(lg) {
      @include font-size(16px, 24px);
    }
  }

  &__date {
    @include make-spacer(mb, 4);

    display: block;
    color: var(--gray);
  }

  &--big {

    .news-card {

      &__content {
        @include media-breakpoint-up(sm) {
          display: grid;
          grid-template-columns: 1fr 235px;
        }

        @include media-breakpoint-up(md) {
          display: grid;
          grid-template-columns: 1fr 420px;
        }
      }

      &__title {
        height: auto;

        @include media-breakpoint-up(lg) {
          @include font-size(24px, 33px);
        }
      }

      &__wrapper {
        @include media-breakpoint-up(md) {
          @include make-spacer(py, 12);
          @include flex($direction: column);
        }
      }

      &__read {
        @include make-spacer(mt, auto, md);
      }
    }

    @include media-breakpoint-up(sm) {
      grid-column: auto / span 2;
    }

    @include media-breakpoint-up(md) {
      grid-column: auto / span 3;
    }
  }
}
