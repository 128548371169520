@charset "UTF-8";

// Settings
// ==================================================
$hamburger-padding-x: 0 !default;
$hamburger-padding-y: 0 !default;
$hamburger-layer-width: 32px !default;
$hamburger-layer-height: 3px !default;
$hamburger-layer-spacing: 8px !default;
$hamburger-layer-color: #fff !default;
$hamburger-layer-border-radius: 0 !default;
$hamburger-hover-opacity: 0.8 !default;
$hamburger-active-layer-color: $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

// Hamburger
// ==================================================

.hamburger {
  display: inline-block;
  transition-duration: 0.3s;
  transition-property: opacity, filter;
  transition-timing-function: linear;
  margin: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: $hamburger-padding-y $hamburger-padding-x;
  overflow: visible;
  text-transform: none;
  color: inherit;
  font: inherit;

  &.is-active {

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: $hamburger-active-layer-color;
    }

    &:hover {
      @if $hamburger-hover-use-filter == true {
        filter: $hamburger-active-hover-filter;
      }

      @else {
        opacity: $hamburger-active-hover-opacity;
      }
    }
  }

  &:hover {
    @if $hamburger-hover-use-filter == true {
      filter: $hamburger-hover-filter;
    }

    @else {
      opacity: $hamburger-hover-opacity;
    }
  }

  &.is-disabled {
    pointer-events: none;
  }
}

.hamburger-box {
  display: inline-block;
  position: relative;
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: $hamburger-layer-height / -2;

  &,
  &::before,
  &::after {
    position: absolute;
    transition-duration: 0.3s;
    transition-property: transform;
    transition-timing-function: ease;
    border-radius: $hamburger-layer-border-radius;
    background-color: $hamburger-layer-color;
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
  }

  &::before,
  &::after {
    display: block;
    content: '';
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}

.hamburger--slider {

  .hamburger-inner {
    top: $hamburger-layer-height / 2;

    &::before {
      top: $hamburger-layer-height + $hamburger-layer-spacing;
      transition-duration: 0.3s;
      transition-property: transform, opacity;
      transition-timing-function: ease;
    }

    &::after {
      top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
    }
  }

  &.is-active {

    .hamburger-inner {
      $y-offset: $hamburger-layer-spacing + $hamburger-layer-height;

      transform: translate3d(0, $y-offset, 0) rotate(45deg);

      &::before {
        transform: rotate(-45deg) translate3d($hamburger-layer-width / -7, $hamburger-layer-spacing * -1, 0);
        opacity: 0;
      }

      &::after {
        transform: translate3d(0, $y-offset * -2, 0) rotate(-90deg);
      }
    }
  }
}
