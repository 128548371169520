.nav {
  @include flex(center, center);

  &__list {
    @include flex($align-items: center);
  }

  &__item {
    text-transform: uppercase;

    + .nav {

      &__item {
        @include make-spacer(ml, 8);
      }
    }
  }

  &__link {
    @include transition(color);
    @include font-size(12px, 24px);

    position: relative;
    text-decoration: none;
    color: var(--white);
    font-family: var(--headings-font-family);
    font-weight: var(--bold);

    &:hover {
      color: var(--yellow);
    }
  }

  &--mobile {
    $top-menu-height: 75px;
    $bottom-menu-height: 75px;

    display: grid;
    flex-direction: column;
    flex-grow: 1;
    max-height: calc(100% - #{$top-menu-height + $bottom-menu-height});
    overflow-x: hidden;
    overflow-y: auto;

    .nav {

      &__list {
        @include make-spacer(py, 7);

        display: block;
      }

      &__link {
        @include font-size(16px);

        @include media-breakpoint-up(sm) {
          @include font-size(18px);

          text-transform: uppercase;
          font-weight: var(--extra-bold);
        }
      }

      &__item {
        @include make-spacer(ml, 0);
        @include make-spacer(mb, 3);

        text-transform: none;

        &:last-child {
          @include make-spacer(mb, 0);
        }
      }
    }

    @include media-breakpoint-up(sm) {
      max-height: calc(100% - #{$top-menu-height});
    }
  }
}
