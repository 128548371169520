.button {
  @include flex(center, center, $display: inline-flex);
  @include make-spacer(m, 0);
  @include make-spacer(py, 4);
  @include make-spacer(px, 2);
  @include make-spacer(px, 4, sm);
  @include font-size(12px, 18px);
  @include transition(#{background-color, box-shadow, color});
  @include size(100%, 50px);

  position: relative;
  outline: none;
  background-color: var(--yellow);
  cursor: pointer;
  vertical-align: middle;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  color: var(--black-primary);
  user-select: none;
  -webkit-appearance: none; // stylelint-disable-line
  -webkit-tap-highlight-color: transparent;
  text-align: center;
  font-family: var(--headings-font-family);
  font-weight: var(--extra-bold);

  &:disabled {
    background-color: var(--yellow-alpha-05);
    pointer-events: none;
  }

  &:focus {
    background-color: var(--yellow-focus);
  }

  &--fluid {
    width: 100%;
  }

  &--reset {
    background-color: transparent;
    color: var(--yellow);

    &:hover {
      color: var(--pink);
    }
  }

  &--white {
    background-color: var(--white);
  }

  &--md {
    @include media-breakpoint-up(sm) {
      @include size(216px, 50px);
    }

    @include media-breakpoint-up(md) {
      @include font-size(16px, 24px);
      @include size(400px, 60px);
    }
  }

  &__icon {
    @include flex;
    @include make-spacer(ml, 2);
  }
}
