.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 4px;
  height: auto;

  @include media-breakpoint-up(sm) {
    grid-gap: 6px;
  }

  &__cell {
    position: relative;
    padding-top: 100%;
    height: 0;
    font-size: 24px;
    text-align: center;

    &--empty {
      background: transparent;
    }

    &--day {
      @include font-size(14px);
      @include flex(center, center);

      color: var(--gray);

      @include media-breakpoint-up(sm) {
        padding-top: 0;
        height: 26px;
      }
    }

    &--count {
      cursor: pointer;
      background-color: var(--black-800);
      color: var(--white);

      &.not-available {
        cursor: not-allowed;
        pointer-events: none;
      }
    }

    &--disabled {
      cursor: not-allowed;
      color: rgba(151, 151, 151, 0.6);
      box-shadow: inset 0 0 0 2px var(--black-800);
      pointer-events: none;
    }

    &--today {
      background-color: var(--black-800);
      box-shadow: inset 0 0 0 1px var(--yellow);
    }

    &.not-available {
      cursor: not-allowed;
      pointer-events: none;

      .calendar__available {
        color: rgba(255, 255, 255, 0.2)
      }
    }
  }

  &__available {
    @include transition(color, 0.2s);
    @include font-size(9px);

    color: var(--yellow);
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 6px;

    @include media-breakpoint-up(sm) {
      @include font-size(12px);

      margin-bottom: 16px;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 20px;
    }
  }

  &__num {
    @include transition(color, 0.2s);
    @include font-size(14px, 20px);

    font-weight: var(--extra-bold);
    margin-top: 6px;

    @include media-breakpoint-up(sm) {
      @include font-size(24px);

      margin-top: 16px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 20px;
    }
  }

  &__wrapper,
  &__trigger {
    @include flex(space-between, center, column);
    @include size(100%);

    position: absolute;
    top: 0;
    left: 0;
    color: inherit;
  }

  &__trigger {
    @include transition(color, 0.2s);
  }

  .u-back-pink-hover {

    &:hover {

      .calendar {

        &__trigger {
          background-color: var(--yellow);
        }

        &__num,
        &__available {
          color: var(--black-700);
        }
      }
    }
  }
}
