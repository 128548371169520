.vr-banner {
  overflow: hidden;

  &__wrapper {
    @include make-spacer(mb, 16);
    @include make-spacer(mb, 30, md);

    color: var(--white);
    position: relative;
  }

  &__paragraph {
    @include make-spacer(mb, 6, sm);
    @include make-spacer(mb, 8, md);
  }

  &__title {
    @include make-spacer(mb, 4, sm)
  }

  &__text {
    max-width: 656px;
  }

  &__seo {
    position: relative;
    max-height: 180px;
    min-height: 140px;
    overflow: hidden;

    &::after {
      @include transition(opacity);

      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      box-shadow: 0 0 20px 20px var(--black-secondary);
      content: '';
    }

    &.is-open {
      max-height: inherit;

      &::after {
        opacity: 0;
      }
    }
  }

  &__bottom {
    @include make-spacer(mt, 5);
    @include make-spacer(mt, 6, sm);
    @include make-spacer(mt, 10, md);

    width: 100%;

    @include media-breakpoint-up(sm) {
      width: auto;
    }
  }

  &__decor {
    display: none;
    position: absolute;
    object-fit: contain;
    top: 0;
    right: 0;
    z-index: 1;

    &--ctrl {
      @include size(140px, 115px);

      top: -32px;
      right: 231px;
      animation: left 20s ease-in-out infinite;

      @include media-breakpoint-up(md) {
        @include size(268px, 220px);

        top: -33px;
        right: 160px;
      }
    }

    &--sa {
      @include size(252px, 170px);

      top: -25px;
      right: -80px;
      animation: swim 10s ease-in-out infinite;

      @include media-breakpoint-up(md) {
        @include size(449px, 303px);

        top: 220px;
        right: -90px;
      }
    }

    @include media-breakpoint-up(sm) {
      display: block;
    }
  }
}

@keyframes swim {
  0%, 100%{
    transform: translateY(0);
  }

  50% {
    transform: translateY(20px);
  }
}

@keyframes left {
  0%, 100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(7%);
  }
}
