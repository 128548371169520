.tabs-section {

  &__header {
    @include make-spacer(pb, 16);
    @include make-spacer(pb, 20, sm);
    @include make-spacer(pb, 30, md);
  }

  &__body {
    @include media-breakpoint-up(md) {
      display: grid;
      grid-template-columns: 1fr 400px;
      grid-gap: 20px;
    }
  }

  &__image {
    position: relative;
    padding-top: 56.25%;
    height: 100%;
    overflow: hidden;

    img {
      @include size(100%);

      display: block;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }

  &__title {
    @include font-size(14px, 16px);

    text-transform: uppercase;
    font-family: var(--headings-font-family);
    font-weight: var(--extra-bold);

    @include media-breakpoint-up(sm) {
      @include font-size(18px, 25px);
    }
  }

  &__content {
    @include flex($direction: column);

    @include media-breakpoint-up(md) {
      background-color: var(--white);
    }
  }

  &__wrapper {
    @include make-spacer(px, 4);
    @include make-spacer(px, 6, md);
    @include make-spacer(py, 6);
    @include make-spacer(py, 8, md);
    @include make-spacer(mt, 6);
    @include make-spacer(mt, 0, md);
    @include make-spacer(mb, 8);
    @include make-spacer(mb, 0, md);

    background-color: var(--white);

    @include media-breakpoint-up(md) {
      flex-grow: 1;
    }
  }

  &__button {
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: auto;
    }
  }
}
