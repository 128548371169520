.price-section {
  font-family: var(--headings-font-family);

  &__card {
    @include make-spacer(py, 4);
    @include flex($direction: column);

    height: 100%;
    color: var(--white);

    &:hover {

      .link {
        color: var(--pink);
      }
    }
  }

  &__info {
    flex-grow: 1;
  }

  &__block {
    @include make-spacer(px, 3);

    height: 288px;
    overflow: hidden;
  }

  &__title {
    @include flex(space-between, center);
    @include make-spacer(mb, 3);
    @include make-spacer(px, 3);
  }

  &__number {
    @include flex(center, center);
    @include make-spacer(mb, 3);

    color: var(--gray);
    font-weight: var(--bold);
  }

  &__text {
    @include make-spacer(mb, 4);
    @include make-spacer(px, 3);

    height: 95px;
    overflow: hidden;
    color: var(--yellow);
    font-family: var(--font-family);

    @include media-breakpoint-up(lg) {
      height: 75px;
    }
  }

  &__discipline {
    @include flex;
    @include make-spacer(pb, 6);
    @include make-spacer(mb, 6);
    @include make-spacer(px, 3);

    border-bottom: 1px solid var(--black-700);

    img {
      @include size(48px);

      display: block;
      object-fit: contain;
    }
  }

  &__link {
    @include flex(center);
    @include make-spacer(pt, 4);

    border-top: 1px solid var(--black-700);
    width: 100%;
    text-transform: uppercase;
  }

  &__content {
    @include make-spacer(px, 6);
    @include make-spacer(px, 0, md);

    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 20px;
    align-items: center;

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__item {
    width: 290px;

    @include media-breakpoint-up(sm) {
      width: 335px;
    }

    @include media-breakpoint-up(md) {
      width: 100%;
    }
  }

  &__wrapper {
    @include make-spacer(pb, 12);
    @include make-spacer(pt, 2);
    @include make-spacer(pb, 0, md);

    display: grid;
    position: relative;
    margin: 0 -15px;
    overflow-y: auto;

    @include media-breakpoint-up(sm) {
      margin: 0 -40px;
    }

    @include media-breakpoint-up(md) {
      margin: 0;
      overflow-y: visible;
    }
  }

  &__spacer {
    margin-top: -10px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--black-700);
  }
}
