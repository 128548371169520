@mixin footer-text-size {
  @include font-size(12px, 16px);
  @include make-spacer(mb, 0);

  @include media-breakpoint-up(sm) {
    @include font-size(14px, 16px);
  }

  @include media-breakpoint-up(md) {
    @include font-size(16px, 18px);
  }
}

.footer {
  @include make-spacer(py, 8);
  @include make-spacer(py, 12, sm);
  @include make-spacer(py, 20, md);

  background-color: var(--black-secondary);
  color: var(--white);

  &__wrap {
    display: grid;
    grid-row: 1;
    grid-column: 3;

    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 20px;
    }
  }

  &__logo {
    @include make-spacer(mb, 6);
    @include make-spacer(mb, 0, sm);
    @include size(100%, 30px);

    img {
      height: 100%;
      max-height: 100%;
    }

    @include media-breakpoint-up(sm) {
      @include size(100%, 64px);
    }
  }

  &__menu {
    @include reset-list;
    @include make-spacer(p, 0);
  }

  &__item {
    @include make-spacer(mb, 2);
    @include make-spacer(mb, 4, md);

    @include media-breakpoint-up(sm) {

      &:last-child {
        @include make-spacer(mb, 0);
      }
    }
  }

  &__link {
    @include footer-text-size;
    @include transition(color);

    text-decoration: none;
    color: var(--white);

    &:hover {
      color: var(--yellow);
    }
  }

  &__text {
    @include footer-text-size;
  }
}
